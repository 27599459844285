import React from "react"
import * as Chakra from "@chakra-ui/react"
import { PageProps } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import "keen-slider/keen-slider.min.css"
import "focus-visible/dist/focus-visible"
import { Global, css } from "@emotion/react"
import FadeTransition from "../components/transitions/fade-transition"
import { useSnackbar } from "react-simple-snackbar"
import WooComState from "../components/state/woocom-state"
import * as htmlEntities from "html-entities"
import useAuth from "../hooks/use-auth"
import axios from "axios"
import Aside from "../components/account/aside"
import Auth from "../components/account/auth"
import { mutate } from "swr"
import siteBackground from "../images/btnc_Startseite.png"
import { useGlobalPreload } from "../hooks/cache-layer-state"
import { Helmet } from "react-helmet"
import "./blobz.css"
import "@fontsource/playfair-display/600.css"
import "@fontsource/playfair-display/700.css"
import "@fontsource/barlow/400.css"
import "@fontsource/barlow/500.css"
import "@fontsource/barlow/600.css"
import "@fontsource/barlow/700.css"

const Layout = ({ children, location, pageContext }: PageProps): React.ReactElement => {
  const GlobalStyles = css`
    .js-focus-visible :focus:not([data-focus-visible-added]) {
      outline: none;
      box-shadow: none;
    }
  `

  const SITE_URL = `https://${process.env.GATSBY_WP_URL}`
  const getSlug = () => {
    if (location.pathname === "/") {
      return "/"
    }
    return location.pathname.split("/")[location.pathname.split("/").length - 1]
  }

  useGlobalPreload()

  const { loggedIn } = useAuth()
  const isBrowser = () => typeof window !== "undefined"

  /*if (
    !loggedIn &&
    process.env.NODE_ENV !== "development" &&
    isBrowser() &&
    window.self === window.top
  ) {
    return <Auth />
  }*/

  if (process.env.GATSBY_IN_SITE_MAINTENANCE === "true") {
    return (
      <Chakra.Flex justify="center" h="100vh" align="center" bg="#212b45" textColor="white">
        <Chakra.Heading>Aktuell ist diese Website nicht verfügbar.</Chakra.Heading>
      </Chakra.Flex>
    )
  }

  return (
    <Chakra.Flex flexGrow={1} width="100%" background={`url(${siteBackground})`} backgroundSize="98px">
      <Helmet>
        <meta property="og:image" content={`${SITE_URL}/${getSlug()}-og-image.png`} />
      </Helmet>
      <Global styles={GlobalStyles} />
      <Chakra.Flex
        css={{
          ".chakra-button": {
            overflow: "hidden",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              width: "auto",
              height: "auto",
              pointerEvents: "none",
              backgroundImage: "radial-gradient(circle, #000 10%, transparent 10.01%)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50%",
              transform: "scale(10,10)",
              opacity: 0,
              transition: "transform 1s, opacity 1.5s",
              borderRadius: "500px",
            },
            "&:active:before": {
              transform: "scale(0,0)",
              opacity: 0.1,
              transition: "0s",
            },
            "@keyframes showanim": {
              "0%": {
                height: "0",
                opacity: "0",
                overflow: "hidden",
              },
              "1%": {
                height: "auto",
                overflow: "unset",
                opacity: "0",
              },
              "100%": {
                height: "auto",
                overflow: "unset",
                opacity: "1",
              },
            },
            "@keyframes hideanim": {
              "0%": {
                opacity: "1",
                height: "auto",
                overflow: "unset",
              },
              "99%": {
                height: "auto",
                opacity: "0",
                overflow: "unset",
              },
              "100%": {
                height: "0",
                opacity: "0",
                overflow: "hidden",
              },
            },
          },
        }}
        width="100%"
        minH="100vh"
        flexDirection="column"
        overflowX="clip"
        backgroundColor="var(--background-col)"
        transition="background-color 0.4s"
      >
        {!pageContext.isPressLess && !location.pathname.includes("leaflet") && <Header />}
        <FadeTransition shouldChange={location.pathname.includes("my-account") ? "" : location.pathname}>
          {location.pathname.includes("my-account") && <AccountLayout location={location}>{children}</AccountLayout>}
          {!location.pathname.includes("my-account") && children}
        </FadeTransition>
        {!pageContext.isPressLess &&
          !location.pathname.includes("checkout") &&
          !location.pathname.includes("leaflet") && <Footer />}
        {!pageContext.isPressLess && <SnackbarWrapper />}
      </Chakra.Flex>
      {!pageContext.isPressLess && <script src="/trustedShops.js" type="text/plain" data-cookieconsent="marketing" />}
    </Chakra.Flex>
  )
}

export default Layout

const SnackbarWrapper = () => {
  const snackbarMessage = WooComState.useGlobalState((gs) => gs.snackbar)
  const [openSnackbar] = useSnackbar()

  React.useEffect(() => {
    if (snackbarMessage) {
      openSnackbar(
        <Chakra.HStack spacing="10px">
          <Chakra.Box pl="10px">{htmlEntities.decode(snackbarMessage)}</Chakra.Box>
        </Chakra.HStack>
      )
      WooComState.setState({ snackbar: null })
    }
  }, [snackbarMessage])

  return <div />
}

const AccountLayout = ({ children, location }) => {
  const { loggedIn, loading } = useAuth()

  React.useEffect(() => {
    if (loggedIn) {
      mutate(
        `https://${process.env.GATSBY_WP_URL}/wp-json/hypematch/v1/user/orders`,
        axios
          .get(`https://${process.env.GATSBY_WP_URL}/wp-json/hypematch/v1/user/orders`, { withCredentials: true })
          .then((res) => JSON.parse(res.data))
      )
      mutate(
        `https://${process.env.GATSBY_WP_URL}/wp-json/hypematch/v1/user/addresses`,
        axios
          .get(`https://${process.env.GATSBY_WP_URL}/wp-json/hypematch/v1/user/addresses`, { withCredentials: true })
          .then((res) => JSON.parse(res.data))
      )
    }
  }, [loggedIn])

  if (loading) {
    return (
      <Chakra.VStack w="100%" flexGrow={1} alignItems="center" justifyContent="center" py={10}>
        <Chakra.Spinner />
      </Chakra.VStack>
    )
  }

  if (loggedIn) {
    return (
      <Chakra.Flex flexGrow={1} width="100%">
        <Chakra.Flex flexGrow={1} mx="auto" maxW="1664px" px={{ base: "16px", xl: "32px" }} width="100%">
          <Chakra.Flex flexGrow={1} flexDirection={{ base: "column", xl: "row" }} alignItems="flex-start">
            <Aside />
            <Chakra.Flex
              height="calc(100% - 40px)"
              margin={{ base: "0 0 20px 0", xl: "20px" }}
              borderRadius="10px"
              boxShadow="0 0 8px #0002"
              backgroundColor="#eae7e4"
              flexGrow={1}
              width={{ base: "100%", xl: "75%" }}
            >
              <FadeTransition shouldChange={location.pathname}>{children}</FadeTransition>
            </Chakra.Flex>
          </Chakra.Flex>
        </Chakra.Flex>
      </Chakra.Flex>
    )
  }

  if (!loggedIn && !loading) {
    return <Auth />
  }
}

import React from "react"
import * as Chakra from "@chakra-ui/react"

interface btncButtonProps extends Chakra.ButtonProps {
  click?: any
  icon?: boolean
  hoverColor?: string
  hoverBackground?: string
  buttonSize?: "sm" | "md" | "xl"
}

const Button = ({
  _hover,
  _focus,
  _active,
  _disabled,
  icon = false,
  click = null,
  color,
  hoverColor,
  background,
  hoverBackground,
  variant = "outline",
  isDisabled = false,
  buttonSize = "md",
  ...props
}: btncButtonProps): React.ReactElement => {
  let buttonFontSize: string
  const options = {}
  switch (buttonSize) {
    case "sm":
      options.px = "20px"
      options.py = "12px"
      buttonFontSize = "13px"
      if (icon) {
        options.width = "52px"
        options.height = "52px"
        options.display = "flex"
        options.px = "0"
        options.py = "0"
      }
      break
    case "md":
      options.px = "32px"
      options.py = "15px"
      buttonFontSize = "16px"
      if (icon) {
        options.width = "58px"
        options.height = "58px"
        options.display = "flex"
        options.px = "0"
        options.py = "0"
      }
      break
    case "xl":
      options.px = "40px"
      options.py = "20px"
      buttonFontSize = "20px"
      if (icon) {
        options.width = "68px"
        options.height = "68px"
        options.display = "flex"
        options.px = "0"
        options.py = "0"
      }
      break
  }

  return (
    <Chakra.Button
      disabled={isDisabled}
      onClick={click}
      borderWidth="2px"
      background={variant != "outline" ? background : "transparent"}
      borderColor={variant != "outline" ? "transparent" : background}
      color={variant != "outline" ? color : background}
      display="inline-block"
      borderRadius="500px"
      transition="0.3s"
      _hover={
        _hover ?? {
          background: variant != "outline" ? hoverBackground : "transparent",
          color: hoverColor ? hoverColor : color,
          borderColor: variant != "outline" ? "transparent" : hoverBackground,
          "*": {
            fill: hoverColor ? hoverColor : color,
          },
        }
      }
      _focus={
        _focus ?? {
          background: variant != "outline" ? hoverBackground : "transparent",
          color: hoverColor ? hoverColor : color,
          borderColor: variant != "outline" ? "transparent" : hoverBackground,
          "*": {
            fill: hoverColor ? hoverColor : color,
          },
        }
      }
      _active={
        _active ?? {
          background: variant != "outline" ? hoverBackground : "transparent",
          color: hoverColor ? hoverColor : color,
          borderColor: variant != "outline" ? "transparent" : hoverBackground,
          "*": {
            fill: hoverColor ? hoverColor : color,
          },
        }
      }
      _disabled={
        _disabled ?? {
          opacity: 0.2,
          pointerEvents: "none",
        }
      }
      {...options}
      {...props}
    >
      <Chakra.Box
        as="span"
        fontSize={buttonFontSize}
        fontStyle="normal"
        fontFamily="Barlow"
        fontWeight="600"
        lineHeight="1"
        letterSpacing="0.05em"
        textTransform="uppercase"
      >
        {props.children}
      </Chakra.Box>
    </Chakra.Button>
  )
}

interface defaultButtonProps extends btncButtonProps {
  buttonStyle:
    | "DarkFilled"
    | "Dark"
    | "LightFilled"
    | "Light"
    | "GreenFilled"
    | "Green"
    | "OrangeFilled"
    | "Orange"
    | "Blue"
    | "BlueFilled"
}

export const DefaultButton = ({ buttonStyle, ...props }: defaultButtonProps): React.ReactElement => {
  switch (buttonStyle) {
    case "DarkFilled":
      return <DarkFilledButton {...props} />
      break
    case "Dark":
      return <DarkButton {...props} />
      break
    case "LightFilled":
      return <LightFilledButton {...props} />
      break
    case "Light":
      return <LightButton {...props} />
      break
    case "GreenFilled":
      return <GreenFilledButton {...props} />
      break
    case "Green":
      return <GreenButton {...props} />
      break
    case "OrangeFilled":
      return <OrangeFilledButton {...props} />
      break
    case "Orange":
      return <OrangeButton {...props} />
      break
    case "BlueFilled":
      return <BlueFilledButton {...props} />
      break
    case "Blue":
      return <BlueButton {...props} />
      break
  }
}
export const DefaultButtonOptions = [
  {
    DarkFilled: "Dark Button Filled",
  },
  {
    Dark: "Dark Button Outline",
  },
  {
    LightFilled: "Light Button Filled",
  },
  {
    Light: "Light Button Outline",
  },
  {
    GreenFilled: "Green Button Filled",
  },
  {
    Green: "Green Button Outline",
  },
  {
    OrangeFilled: "Orange Button Filled",
  },
  {
    Orange: "Orange Button Outline",
  },
  {
    BlueFilled: "Blue Button Filled",
  },
  {
    Blue: "Blue Button Outline",
  },
]

export const DarkFilledButton = (props: btncButtonProps): React.ReactElement => {
  return <Button background="#212B45" color="#ffffff" hoverBackground="#FF5038" variant="solid" {...props} />
}
export const DarkButton = (props: btncButtonProps): React.ReactElement => {
  return <Button background="#212B45" color="#ffffff" hoverBackground="#FF5038" hoverColor="#FF5038" {...props} />
}

export const LightFilledButton = (props: btncButtonProps): React.ReactElement => {
  return (
    <Button
      background="#F1EFEC"
      color="#003A74"
      hoverBackground="#FF5038"
      hoverColor="#ffffff"
      variant="solid"
      {...props}
    />
  )
}
export const LightButton = (props: btncButtonProps): React.ReactElement => {
  return <Button background="#F1EFEC" color="#000000" hoverBackground="#FF5038" hoverColor="#FF5038" {...props} />
}

export const GreenFilledButton = (props: btncButtonProps): React.ReactElement => {
  return <Button background="#78BD76" color="#ffffff" hoverBackground="#50A050" variant="solid" {...props} />
}
export const GreenButton = (props: btncButtonProps): React.ReactElement => {
  return <Button background="#78BD76" color="#ffffff" hoverBackground="#50A050" hoverColor="#50A050" {...props} />
}

export const OrangeFilledButton = (props: btncButtonProps): React.ReactElement => {
  return <Button background="#FF5038" color="#ffffff" hoverBackground="#F02000" variant="solid" {...props} />
}
export const OrangeButton = (props: btncButtonProps): React.ReactElement => {
  return <Button background="#FF5038" color="#ffffff" hoverBackground="#F02000" hoverColor="#F02000" {...props} />
}

export const BlueFilledButton = (props: btncButtonProps): React.ReactElement => {
  return <Button background="#212B45" color="#ffffff" hoverBackground="#003A74" variant="solid" {...props} />
}
export const BlueButton = (props: btncButtonProps): React.ReactElement => {
  return <Button background="#212B45" color="#ffffff" hoverBackground="#003A74" hoverColor="#003A74" {...props} />
}

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-lost-password-tsx": () => import("./../../../src/pages/lost-password.tsx" /* webpackChunkName: "component---src-pages-lost-password-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../../../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */),
  "component---src-pages-my-account-account-details-tsx": () => import("./../../../src/pages/my-account/account-details.tsx" /* webpackChunkName: "component---src-pages-my-account-account-details-tsx" */),
  "component---src-pages-my-account-addresses-tsx": () => import("./../../../src/pages/my-account/addresses.tsx" /* webpackChunkName: "component---src-pages-my-account-addresses-tsx" */),
  "component---src-pages-my-account-index-tsx": () => import("./../../../src/pages/my-account/index.tsx" /* webpackChunkName: "component---src-pages-my-account-index-tsx" */),
  "component---src-pages-my-account-orders-tsx": () => import("./../../../src/pages/my-account/orders.tsx" /* webpackChunkName: "component---src-pages-my-account-orders-tsx" */),
  "component---src-pages-order-received-tsx": () => import("./../../../src/pages/order-received.tsx" /* webpackChunkName: "component---src-pages-order-received-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-all-wp-post-js": () => import("./../../../src/templates/allWpPost.js" /* webpackChunkName: "component---src-templates-all-wp-post-js" */),
  "component---src-templates-wp-leaflet-og-image-tsx": () => import("./../../../src/templates/WpLeaflet.og-image.tsx" /* webpackChunkName: "component---src-templates-wp-leaflet-og-image-tsx" */),
  "component---src-templates-wp-leaflet-tsx": () => import("./../../../src/templates/WpLeaflet.tsx" /* webpackChunkName: "component---src-templates-wp-leaflet-tsx" */),
  "component---src-templates-wp-page-js": () => import("./../../../src/templates/WpPage.js" /* webpackChunkName: "component---src-templates-wp-page-js" */),
  "component---src-templates-wp-product-category-tsx": () => import("./../../../src/templates/WpProductCategory.tsx" /* webpackChunkName: "component---src-templates-wp-product-category-tsx" */),
  "component---src-templates-wp-product-og-image-tsx": () => import("./../../../src/templates/WpProduct.og-image.tsx" /* webpackChunkName: "component---src-templates-wp-product-og-image-tsx" */),
  "component---src-templates-wp-product-tsx": () => import("./../../../src/templates/WpProduct.tsx" /* webpackChunkName: "component---src-templates-wp-product-tsx" */)
}


import React from "react"
import * as Chakra from "@chakra-ui/react"

const PasswordStrengthBar = ({ password }) => {
  const newTestedResult = (pass) => {
    if (/^(?=(.*[a-z]){3,})(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,})(?=(.*[!@#$%^&*()\-__+.]){2,}).{12,}$/.test(pass)) return 4
    if (/^(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/.test(pass)) return 3
    if (/^(?=(.*[a-z]){2,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{4,}$/.test(pass)) return 2
    if (/^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,}).{4,}$/.test(pass)) return 1

    return 0
  }

  return (
    <Chakra.Flex width="100%" justify="center" align="center" flexDirection="column" pb="32px">
      <Progress value={newTestedResult(password)} max={4} />
    </Chakra.Flex>
  )
}

export default PasswordStrengthBar

const Progress = ({ value, max }) => {
  return (
    <Chakra.Box width="100%">
      <Chakra.Text
        fontFamily="Barlow"
        fontWeight="600"
        fontSize="16px"
        lineHeight="24px"
        textAlign="center"
        color="#212b45"
        pb="11px"
      >
        Passwortstärke {value + 1}/{max + 1}
      </Chakra.Text>
      <Chakra.Box display="flex" width="100%" align="center" justify="space-between">
        <Chakra.Box
          height="8px"
          width="20%"
          mr="4px"
          bg={value + 1 == 1 || value + 1 > 1 ? "#F25F5C" : "#E0DDDA"}
          transition="all 0.3s"
        />
        <Chakra.Box
          height="8px"
          width="20%"
          mr="4px"
          bg={value + 1 > 1 || value + 1 == 2 ? "#F25F5C" : "#E0DDDA"}
          transition="all 0.3s"
        />
        <Chakra.Box
          height="8px"
          width="20%"
          mr="4px"
          bg={value + 1 == 3 || value + 1 > 3 ? "#247BA0" : "#E0DDDA"}
          transition="all 0.3s"
        />
        <Chakra.Box
          height="8px"
          width="20%"
          mr="4px"
          bg={value + 1 == 4 || value + 1 > 4 ? "#78BD76" : "#E0DDDA"}
          transition="all 0.3s"
        />
        <Chakra.Box height="8px" width="20%" bg={value + 1 == 5 ? "#78BD76" : "#E0DDDA"} transition="all 0.3s" />
      </Chakra.Box>
    </Chakra.Box>
  )
}

import * as React from "react"
import { ApolloProvider } from "@apollo/client"
import SnackbarProvider from "react-simple-snackbar"
import { AuthProvider } from "./src/hooks/use-auth"
import { SWRConfig } from "swr"
import axios from "axios"
import fetch from "cross-fetch"
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import ReactDOM from "react-dom"

const link = createHttpLink({
  uri: `https://${process.env.GATSBY_WP_URL}/graphql`,
  credentials: "include",
  fetch,
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
})

export const onClientEntry = () => {
  console.log("GATSBY_IN_SITE_MAINTENANCE", process.env.GATSBY_IN_SITE_MAINTENANCE)
  if (process.env.GATSBY_IN_SITE_MAINTENANCE === "true" && !window.location.pathname.startsWith("/maintenance")) {
    window.location = "/maintenance"
  }
}

export const onRouteUpdate = () => {
  console.log("UPDATE GATSBY_IN_SITE_MAINTENANCE", process.env.GATSBY_IN_SITE_MAINTENANCE)
  if (process.env.GATSBY_IN_SITE_MAINTENANCE === "true" && !window.location.pathname.startsWith("/maintenance")) {
    window.location = "/maintenance"
  }
}

export const wrapRootElement = ({ element }) => (
  <SnackbarProvider>
    <ApolloProvider client={client}>
      <SWRConfig
        value={{
          refreshInterval: 3000,
          fetcher: (resource, init) =>
            axios.get(resource, { withCredentials: true }).then((res) => JSON.parse(res.data)),
        }}
      >
        <AuthProvider>{element}</AuthProvider>
      </SWRConfig>
    </ApolloProvider>
  </SnackbarProvider>
)

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    if (process.env.NODE_ENV === "development") {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      if (urlParams.has("skip-client")) {
        return
      }
    }

    ReactDOM.hydrate(element, container, callback)
  }
}

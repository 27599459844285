import React from "react"
import WooComState from "../components/state/woocom-state"
import { GlobalState } from "react-gstate"
import useSWR from "swr"

export const fetcher = (url) => fetch(url).then((res) => res.json())

export const useGlobalPreload = () => {
  const urlToPreload = CacheLayerState.useGlobalState((gs) => gs.urlToPreload)

  const previousCartResponse = WooComState.useGlobalState(
    (gs) => gs.previousCartResponse
  )

  return (
    <>
      <LoaderHook url={urlToPreload} />
      {previousCartResponse?.items &&
        previousCartResponse.items.map((item) => (
          <LoaderHook key={item.id} url={`cocart/v1/products/${item.id}`} />
        ))}
    </>
  )
}

const usePreloadedUrl = (endpoint) => {
  const { data, error, isValidating } = useSWR(
    `https://${process.env.GATSBY_WP_URL}/wp-json/${endpoint}`,
    fetcher
  )
  return { data, error, isValidating }
}

const LoaderHook = ({ url }) => {
  useSWR(`https://${process.env.GATSBY_WP_URL}/wp-json/${url}`, fetcher)

  return <></>
}

class CacheLayer extends GlobalState<{
  knownUrls: string[]
  urlToPreload: string
}> {
  preloadUrl = (url) => {
    CacheLayerState.setState({
      knownUrls: Array.from(new Set(...CacheLayerState.state.knownUrls, url)),
    })
  }

  usePreloadedUrl = usePreloadedUrl
}

const CacheLayerState = new CacheLayer({
  knownUrls: [],
  urlToPreload: "",
})

export default CacheLayerState

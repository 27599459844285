import React, { useEffect, useRef, useState } from "react"
import * as Chakra from "@chakra-ui/react"
import { useLocation } from "@reach/router"
import Profile from "../../images/profile.svg"
import { lighten } from "@chakra-ui/theme-tools"
import { CloseIcon } from "@chakra-ui/icons"
import useAuth, { GET_USER } from "../../hooks/use-auth"
import { gql, useMutation } from "@apollo/client"
import axios from "axios"
import PasswordStrengthBar from "../password-strength-bar"
import { navigate } from "gatsby"
import { DefaultButton } from "../buttons"

const LOG_IN = gql`
  mutation logIn($login: String!, $password: String!) {
    loginWithCookies(input: { login: $login, password: $password }) {
      status
    }
  }
`

const LoginModal = ({onClick}) => {
  const { loggedIn } = useAuth()

  const location = useLocation()

  const { isOpen, onOpen, onClose } = Chakra.useDisclosure()

  const [alternativeStyle, setAlternativeStyle] = useState(false)

  const [tabIndex, setTabIndex] = React.useState(location?.hash === "#register" || location?.hash === "#42" ? 1 : 0)

  const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  useEffect(() => {
    if (location.hash === "#register" || location.hash === "#login" || location.hash === "#42") {
      if (location.hash === "#42") {
        setAlternativeStyle(true)
      } else {
        setAlternativeStyle(false)
      }
      onOpen()
    } else {
      setAlternativeStyle(false)
    }
  }, [location?.state?.key])

  const ProfileClick = () => {
    if(loggedIn){
        navigate("/my-account")
        if (onClick) {
          onClick()
        }
    } else {
        onOpen()
    }
  }

  return (
    <>
      <Chakra.Button
        cursor="pointer"
        width="48px"
        height="48px"
        alignItems="center"
        marginRight={{ base: "0px", xl: "16px" }}
        borderRadius="50%"
        flexDirection="column"
        justifyContent="center"
        backgroundColor="#212b45"
        display={{ base: "inline-flex", xl: "flex" }}
        onClick={ProfileClick}
        transition="all 0.3s"
        _hover={{
          backgroundColor: lighten("#212b45", 7),
        }}
        color="#fff"
      >
        <Profile />
      </Chakra.Button>

      <Chakra.Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside" isCentered>
        <Chakra.ModalOverlay bg="rgba(33, 43, 69, 0.8)" />
        <Chakra.ModalContent
          bg="#F1EFEC"
          width="100%"
          maxWidth={{ base: "100%", md: "784px" }}
          borderRadius={{ base: 0, md: "32px" }}
          minHeight={{ base: "100%", md: "0" }}
          my="0"
          position={{ base: "absolute", md: "relative" }}
          top="0"
          sx={
            tabIndex === 1 || alternativeStyle
              ? alternativeStyle
                ? { "@media (max-height: 1086px) and (min-width: 48em)": { position: "absolute", top: "25px" } }
                : { "@media (max-height: 1159px) and (min-width: 48em)": { position: "absolute", top: "25px" } }
              : { "@media (max-height: 758px) and (min-width: 48em)": { position: "absolute", top: "25px" } }
          }
        >
          <DefaultButton
            buttonStyle="Dark"
            buttonSize="sm"
            icon
            position="absolute"
            top={{ base: "20px", md: "24px" }}
            right="24px"
            onClick={onClose}
            width="32px"
            height="32px"
          >
            <CloseIcon />
          </DefaultButton>
          {!alternativeStyle ? (
            <Chakra.Tabs
              isFitted
              variant="enclosed"
              width="100%"
              pt="60px"
              pb="48px"
              px="16px"
              defaultIndex={location?.hash === "#register" ? 1 : 0}
              index={tabIndex}
              onChange={handleTabsChange}
            >
              <Chakra.TabList>
                <Chakra.Tab _selected={{ bg: "#EAE7E4" }} bg="#F1EFEC">
                  Einloggen
                </Chakra.Tab>
                <Chakra.Tab _selected={{ bg: "#EAE7E4" }} bg="#F1EFEC">
                  Registrieren
                </Chakra.Tab>
              </Chakra.TabList>
              <Chakra.TabPanels>
                <Chakra.TabPanel>
                  <Login onClose={onClose} />
                </Chakra.TabPanel>
                <Chakra.TabPanel>
                  <Register onClose={onClose} />
                </Chakra.TabPanel>
              </Chakra.TabPanels>
            </Chakra.Tabs>
          ) : (
            <Chakra.Box width="100%" pt="60px" pb="48px" px="16px">
              <Register onClose={onClose} alternativeStyle={alternativeStyle} />
            </Chakra.Box>
          )}
        </Chakra.ModalContent>
      </Chakra.Modal>
    </>
  )
}

export default LoginModal

const Login = ({ onClose }) => {
  const [logIn, { loading, error }] = useMutation(LOG_IN, {
    refetchQueries: [{ query: GET_USER }],
  })

  const errorMessage = error?.message || ""
  const isEmailValid =
    !errorMessage.includes("empty_email") &&
    !errorMessage.includes("empty_username") &&
    !errorMessage.includes("invalid_email") &&
    !errorMessage.includes("invalid_username")
  const isPasswordValid = !errorMessage.includes("empty_password") && !errorMessage.includes("incorrect_password")

  function handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const { email, password } = Object.fromEntries(data)
    logIn({
      variables: {
        login: email,
        password,
      },
    })
      .then(() => {
        onClose()
      })
      .catch((error) => {
        console.error(error)
      })
  }
  return (
    <>
      <Chakra.Text fontFamily="Barlow" fontSize="24px" lineHeight="36px" textAlign="center" color="#212b45" pb="32px">
        Melde Dich mit Deinen Nutzerdaten an.
      </Chakra.Text>
      <Chakra.Box width="100%" bg={{ base: "transparent", md: "#EAE7E4" }} borderRadius="16px">
        <Chakra.Box as="form" onSubmit={handleSubmit} width="100%" padding={{ base: "0px", md: "32px 48px 48px 48px" }}>
          <Chakra.FormControl width="100%" mb="16px">
            <Chakra.FormLabel
              fontFamily="Barlow"
              fontSize="16px"
              lineHeight="24px"
              color="#212b45"
              width="100%"
              px="30px"
              pb="7.5px"
            >
              E-Mail-Adresse
            </Chakra.FormLabel>
            <Chakra.Input
              width="100%"
              borderRadius="4px"
              height="56px"
              bg="white"
              id="log-in-email"
              name="email"
              autoComplete="username"
              required
            />
            {!isEmailValid ? <p className="error-message">Ungültige E-Mail. Bitte versuche es erneut.</p> : null}
          </Chakra.FormControl>

          <Chakra.FormControl width="100%" mb="32px">
            <Chakra.FormLabel
              fontFamily="Barlow"
              fontSize="16px"
              lineHeight="24px"
              color="#212b45"
              width="100%"
              px="30px"
              pb="7.5px"
            >
              Passwort
            </Chakra.FormLabel>
            <Chakra.Input
              width="100%"
              borderRadius="4px"
              height="56px"
              bg="white"
              id="log-in-password"
              type="password"
              name="password"
              autoComplete="current-password"
              required
            />
            {!isPasswordValid ? <p className="error-message">Ungültiges Passwort. Bitte versuche es erneut.</p> : null}
          </Chakra.FormControl>

          <Chakra.Text fontFamily="Barlow" fontSize="13px" lineHeight="16px" color="#212b45" pb="32px" width="100%">
            Indem du dich einloggst, erklärst du dich mit unseren{" "}
            <Chakra.Link href="/agb" style={{ textDecoration: "underline" }}>
              AGB
            </Chakra.Link>{" "}
            und{" "}
            <Chakra.Link href="/datenschutz" style={{ textDecoration: "underline" }}>
              Datenschutzbestimmungen
            </Chakra.Link>{" "}
            einverstanden.
          </Chakra.Text>
          <Chakra.Button
            fontFamily="Barlow"
            type="submit"
            isLoading={loading}
            fontWeight="600"
            fontSize="16px"
            lineHeight="16px"
            color="white"
            bg="#FF5038"
            display="flex"
            justify="center"
            borderRadius="40px"
            _hover={{ bg: "white", color: "#ff5038" }}
            _active={{ bg: "white", color: "#ff5038" }}
            py="16px"
            px="32px"
            textTransform="uppercase"
            transition="all 0.3s"
            width="100%"
            mb="20px"
          >
            Anmelden
          </Chakra.Button>
          <Chakra.Link
            href="/lost-password"
            color="#212B45"
            _hover={{ color: "#424347" }}
            fontSize="14px"
            fontFamily="Barlow"
          >
            Passwort vergessen?
          </Chakra.Link>
        </Chakra.Box>
      </Chakra.Box>
    </>
  )
}

const Register = ({ onClose, alternativeStyle }) => {
  const [logIn, { error }] = useMutation(LOG_IN, {
    refetchQueries: [{ query: GET_USER }],
  })

  const [responseText, setResponseText] = React.useState("")
  const [loading, setLoading] = React.useState(false)

  const [password, setPassword] = React.useState("")
  const changePassword = (e) => {
    setPassword(e.target.value)
  }

  const [passwordRepeat, setPasswordRepeat] = React.useState("")
  const changePasswordRepeat = (e) => {
    setPasswordRepeat(e.target.value)
  }

  function handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.target)
    const { email, firstname, lastname, password, agbapprove, newslettersignup } = Object.fromEntries(data)

    if (password !== passwordRepeat) {
      setResponseText("Leider stimmen die von dir eingegebenen Passwörter nicht überein.")
      return
    }

    setLoading(true)
    axios
      .post(`https://${process.env.GATSBY_WP_URL}/wp-json/hypematch/v1/user/register/explicit`, {
        email,
        firstname,
        lastname,
        password,
        agbapprove,
        newslettersignup,
      })
      .then((response) => {
        setResponseText(response.data.message)
        logIn({
          variables: {
            login: email,
            password,
          },
        })
          .then(() => {
            onClose()
          })
          .catch((error) => {
            console.error(error)
          })
      })
      .catch((error) => {
        setResponseText(error.response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const [canSignUp, setCanSignUp] = React.useState(false)

  return (
    <>
      <Chakra.Text fontFamily="Barlow" fontSize="24px" lineHeight="36px" textAlign="center" color="#212b45" pb="32px">
        {alternativeStyle ? "Erstelle ein Kundenkonto und erhalte deinen 42%-Gutschein" : "Erstelle ein Kundenkonto."}
      </Chakra.Text>
      <Chakra.Box width="100%" bg={{ base: "transparent", md: "#EAE7E4" }} borderRadius="16px">
        <Chakra.Box
          as="form"
          id="register"
          onSubmit={handleSubmit}
          width="100%"
          padding={{ base: "0px", md: "32px 48px 48px 48px" }}
        >
          <Chakra.Stack
            flexDirection="row"
            width="100%"
            mb="16px"
            align="center"
            justify="space-between"
            css={{
              "@media (max-width: 320px)": {
                flexDirection: "column",
              },
            }}
          >
            <Chakra.FormControl
              width="100%"
              pr="16px"
              css={{
                "@media (max-width: 320px)": {
                  paddingRight: "0px",
                  paddingBottom: "16px",
                },
              }}
            >
              <Chakra.FormLabel
                fontFamily="Barlow"
                fontSize="16px"
                lineHeight="24px"
                color="#212b45"
                width="100%"
                px="30px"
                pb="7.5px"
              >
                Vorname*
              </Chakra.FormLabel>
              <Chakra.Input
                width="100%"
                borderRadius="4px"
                height="56px"
                bg="white"
                type="text"
                id="firstname"
                name="firstname"
                required
              />
            </Chakra.FormControl>
            <Chakra.FormControl width="100%" mt="0px !important">
              <Chakra.FormLabel
                fontFamily="Barlow"
                fontSize="16px"
                lineHeight="24px"
                color="#212b45"
                width="100%"
                px="30px"
                pb="7.5px"
              >
                Nachname*
              </Chakra.FormLabel>
              <Chakra.Input
                width="100%"
                borderRadius="4px"
                height="56px"
                bg="white"
                type="text"
                id="lastname"
                name="lastname"
                required
              />
            </Chakra.FormControl>
          </Chakra.Stack>
          <Chakra.FormControl width="100%" mb="14px">
            <Chakra.FormLabel
              fontFamily="Barlow"
              fontSize="16px"
              lineHeight="24px"
              color="#212b45"
              width="100%"
              px="30px"
              pb="7.5px"
            >
              Email*
            </Chakra.FormLabel>
            <Chakra.Input
              width="100%"
              borderRadius="4px"
              height="56px"
              bg="white"
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              required
            />
          </Chakra.FormControl>
          <Chakra.FormControl width="100%" mb="6px">
            <Chakra.FormLabel
              fontFamily="Barlow"
              fontSize="16px"
              lineHeight="24px"
              color="#212b45"
              width="100%"
              px="30px"
              pb="7.5px"
            >
              Passwort*
            </Chakra.FormLabel>
            <Chakra.Input
              width="100%"
              borderRadius="4px"
              height="56px"
              bg="white"
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={changePassword}
              required
            />
          </Chakra.FormControl>
          <Chakra.Text fontFamily="Barlow" fontSize="16px" lineHeight="24px" color="#212b45" pb="5px">
            Dein Passwort muss mind. 8 Zeichen lang sein, mind. einen Großbuchstaben, mind. eine Zahl und sollte ein
            Sonderzeichen (z. B. ?) enthalten.
          </Chakra.Text>
          <PasswordStrengthBar password={password} />
          <Chakra.FormControl width="100%" mb="6px">
            <Chakra.FormLabel
              fontFamily="Barlow"
              fontSize="16px"
              lineHeight="24px"
              color="#212b45"
              width="100%"
              px="30px"
              pb="7.5px"
            >
              Passwort wiederholen*
            </Chakra.FormLabel>
            <Chakra.Input
              width="100%"
              borderRadius="4px"
              height="56px"
              bg="white"
              type="password"
              id="password-repeat"
              name="password-repeat"
              value={passwordRepeat}
              onChange={changePasswordRepeat}
              required
            />
          </Chakra.FormControl>
          <Chakra.VStack width="100%" align="start">
            <Chakra.HStack align="flex-start" width="100%" pb="10px">
              <input
                id="agb_approve"
                onChange={(e) => setCanSignUp(e.target.checked)}
                type="radio"
                name="agbapprove"
                style={{ width: "24px", height: "24px" }}
              />
              <label
                htmlFor="agb_approve"
                style={{
                  fontFamily: "Barlow",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#212b45",
                }}
              >
                Ja, ich erkläre mich mit den{" "}
                <Chakra.Link href="/agb" style={{ textDecoration: "underline" }}>
                  AGB
                </Chakra.Link>
                , der{" "}
                <Chakra.Link href="/datenschutz" style={{ textDecoration: "underline" }}>
                  Datenschutzerklärung
                </Chakra.Link>{" "}
                sowie der Widerrufsbelehrung von BTNC einverstanden.
              </label>
            </Chakra.HStack>
            <Chakra.HStack align="flex-start" width="100%" pb="26px">
              <input
                id="newsletter_signup"
                name="newslettersignup"
                type="radio"
                style={{ width: "24px", height: "24px" }}
              />
              <label
                htmlFor="newsletter_signup"
                style={{
                  fontFamily: "Barlow",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#212b45",
                }}
              >
                Ja, ich möchte den Newsletter von BTNC abonnieren. Ich kann mich jederzeit wieder vom Newsletter
                abmelden.
              </label>
            </Chakra.HStack>
          </Chakra.VStack>
          <Chakra.Button
            fontFamily="Barlow"
            fontWeight="600"
            fontSize="16px"
            lineHeight="16px"
            color="white"
            type="submit"
            bg="#FF5038"
            display="flex"
            justify="center"
            borderRadius="40px"
            _hover={{ bg: "white", color: "#ff5038" }}
            _active={{ bg: "white", color: "#ff5038" }}
            py="16px"
            px="32px"
            textTransform="uppercase"
            transition="all 0.3s"
            width="100%"
            isLoading={loading}
            disabled={loading || !canSignUp}
            opacity={loading || !canSignUp ? 0.4 : 1}
          >
            {alternativeStyle ? "REGISTRIEREN UND RABATT SICHERN" : "REGISTRIEREN"}
          </Chakra.Button>
          <Chakra.Text fontSize="17px" fontFamily="Barlow" color="#212B45" py="20px">
            {responseText}
          </Chakra.Text>
        </Chakra.Box>
      </Chakra.Box>
    </>
  )
}

import React from "react"
import * as Chakra from "@chakra-ui/react"
import { GET_USER } from "../../hooks/use-auth"
import { gql, useMutation } from "@apollo/client"
import axios from "axios"

const LOG_IN = gql`
  mutation logIn($login: String!, $password: String!) {
    loginWithCookies(input: { login: $login, password: $password }) {
      status
    }
  }
`

const Auth = () => {
  return (
    <Chakra.Box width="100%">
      <Chakra.Stack
        direction={{ base: "column", lg: "row" }}
        py={12}
        justify="space-between"
        align="stretch"
        px="32px"
        w="100%"
        maxW="1664px"
        mx="auto"
        h="100%"
      >
        <Chakra.Box
          w="45%"
          flexShrink={0}
          css={{
            "@media (max-width: 991px)": {
              width: "100%",
              maxWidth: "568px",
              marginBottom: "80px",
            },
          }}
        >
          <Login />
        </Chakra.Box>
        <Chakra.Box d={{ base: "none", lg: "block" }} height="auto">
          <Chakra.Divider
            width="3px"
            orientation="vertical"
            borderColor="#212B45"
          />
        </Chakra.Box>
        <Chakra.Box
          w="45%"
          flexShrink={0}
          css={{
            "@media (max-width: 991px)": {
              width: "100%",
              maxWidth: "568px",
            },
          }}
        >
          {/*<Register />*/}
        </Chakra.Box>
      </Chakra.Stack>
    </Chakra.Box>
  )
}

export default Auth

const Login = () => {
  const [logIn, { loading, error }] = useMutation(LOG_IN, {
    refetchQueries: [{ query: GET_USER }],
  })

  const errorMessage = error?.message || ""
  const isEmailValid =
    !errorMessage.includes("empty_email") &&
    !errorMessage.includes("empty_username") &&
    !errorMessage.includes("invalid_email") &&
    !errorMessage.includes("invalid_username")
  const isPasswordValid =
    !errorMessage.includes("empty_password") &&
    !errorMessage.includes("incorrect_password")

  function handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const { email, password } = Object.fromEntries(data)
    logIn({
      variables: {
        login: email,
        password,
      },
    }).catch((error) => {
      console.error(error)
    })
  }

  return (
    <Chakra.VStack
      justify="start"
      align="start"
      width="100%"
      as="form"
      onSubmit={handleSubmit}
    >
      <Chakra.Box
        as="fieldset"
        width="100%"
        disabled={loading}
        aria-busy={loading}
      >
        <Chakra.Heading
          textTransform="uppercase"
          fontWeight="800"
          fontSize="23px"
          pb="40px"
          fontFamily="Barlow"
          color="#212B45"
        >
          Einloggen
        </Chakra.Heading>
        <Chakra.FormControl required pb="40px">
          <Chakra.FormLabel
            fontWeight="600"
            fontFamily="Barlow"
            color="#212B45"
            fontSize="18px"
            pb="10px"
          >
            Benutzername oder E-mail Adresse
          </Chakra.FormLabel>
          <Chakra.Input
            id="log-in-email"
            name="email"
            autoComplete="username"
            required
            borderRadius="8px"
            h="56px"
            bg="white"
            px="15px"
            border="1px solid #212B45"
            // outline="none"
            fontFamily="Barlow"
          />
        </Chakra.FormControl>
        {!isEmailValid ? (
          <p className="error-message">
            Ungültige E-Mail. Bitte versuche es erneut.
          </p>
        ) : null}
        {!isPasswordValid ? (
          <p className="error-message">
            Ungültiges Passwort. Bitte versuche es erneut.
          </p>
        ) : null}
        <Chakra.FormControl required pb="40px">
          <Chakra.FormLabel
            fontWeight="600"
            fontFamily="Barlow"
            color="#212B45"
            fontSize="18px"
            pb="10px"
          >
            Passwort
          </Chakra.FormLabel>
          <Chakra.Input
            id="log-in-password"
            type="password"
            name="password"
            autoComplete="current-password"
            required
            bg="white"
            h="56px"
            borderRadius="8px"
            px="15px"
            border="1px solid #212B45"
            fontFamily="Barlow"
          />
        </Chakra.FormControl>
        <Chakra.FormControl pb="20px">
          <Chakra.Checkbox defaultIsChecked>
            Erinnere dich an mich
          </Chakra.Checkbox>
        </Chakra.FormControl>
        <Chakra.Button
          type="submit"
          py="16px"
          px="32px"
          mb="20px"
          background="#212B45"
          color="#ffffff"
          w="100%"
          borderRadius="24px"
          isLoading={loading}
          textTransform="uppercase"
          fontSize="16px"
          lineHeight="16px"
          letterSpacing="0.8px"
          fontFamily="Barlow"
        >
          Einloggen
        </Chakra.Button>
      </Chakra.Box>
      <Chakra.Link
        href="/lost-password"
        color="#212B45"
        _hover={{ color: "#424347" }}
        fontSize="14px"
        fontFamily="Barlow"
      >
        Passwort Vergessen?
      </Chakra.Link>
    </Chakra.VStack>
  )
}

const Register = () => {
  const [responseText, setResponseText] = React.useState(
    "Ein passwort wird an ihre e-mail-adresse gesendet."
  )
  const [loading, setLoading] = React.useState(false)

  function handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const { email } = Object.fromEntries(data)
    setLoading(true)
    axios
      .post(
        `https://${process.env.GATSBY_WP_URL}/wp-json/hypematch/v1/user/register`,
        { email }
      )
      .then((response) => {
        setResponseText(response.data.message)
      })
      .catch((error) => {
        setResponseText(error.response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Chakra.VStack
      justify="start"
      align="start"
      width="100%"
      onSubmit={handleSubmit}
      as="form"
    >
      <Chakra.Box
        as="fieldset"
        width="100%"
        disabled={loading}
        aria-busy={loading}
        h="100%"
        display="flex"
        flexDirection="column"
      >
        <Chakra.Heading
          textTransform="uppercase"
          fontWeight="800"
          fontSize="23px"
          pb="40px"
          fontFamily="Barlow"
          color="#212B45"
        >
          registrieren
        </Chakra.Heading>
        <Chakra.FormControl required pb="40px">
          <Chakra.FormLabel
            fontWeight="600"
            fontFamily="Barlow"
            color="#212B45"
            fontSize="18px"
            pb="10px"
          >
            E-Mail-Addresse
          </Chakra.FormLabel>
          <Chakra.Input
            type="email"
            name="email"
            autoComplete="email"
            required
            borderRadius="8px"
            h="56px"
            bg="white"
            px="15px"
            border="1px solid #212B45"
            fontFamily="Barlow"
          />
        </Chakra.FormControl>
        <Chakra.Text
          fontSize="17px"
          fontFamily="Barlow"
          color="#212B45"
          pb="20px"
        >
          {responseText}
        </Chakra.Text>
        <Chakra.Text
          fontSize="14px"
          pb="20px"
          fontFamily="Barlow"
          color="#212B45"
        >
          Ihre personenbezogenen Daten werden verwendet, um Ihre Erfahrung auf
          dieser Website zu unterstützen, den Zugriff auf Ihr Konto zu verwalten
          und für andere Zwecke, die in unserer Datenschutzrichtlinie
          beschrieben sind.
        </Chakra.Text>
        <Chakra.Button
          type="submit"
          py="16px"
          px="32px"
          mb="44px"
          background="#212B45"
          color="#ffffff"
          w="100%"
          borderRadius="24px"
          isLoading={loading}
          textTransform="uppercase"
          fontSize="16px"
          lineHeight="16px"
          letterSpacing="0.8px"
          fontFamily="Barlow"
          mt="auto"
        >
          registrieren
        </Chakra.Button>
      </Chakra.Box>
    </Chakra.VStack>
  )
}

/* eslint-disable @typescript-eslint/no-empty-function */
import { useQuery, gql, ApolloError, useMutation } from "@apollo/client"
import React, { createContext, useContext, ReactNode } from "react"
import WooComState from "../components/state/woocom-state"

export interface User {
  id: string
  databaseId: number
  firstName: string
  name: string
  lastName: string
  email: string
  capabilities: string[]
  avatar: Avatar
}

interface Avatar {
  url: string
}

interface AuthData {
  loggedIn: boolean
  user?: User
  loading: boolean
  error?: ApolloError
  logout: () => void
}

const DEFAULT_STATE: AuthData = {
  loggedIn: false,
  user: undefined,
  loading: false,
  error: undefined,
  logout: () => {},
}

export const AuthContext = createContext(DEFAULT_STATE)

export const GET_USER = gql`
  query getUser {
    viewer {
      id
      databaseId
      firstName
      lastName
      name
      email
      capabilities
      avatar {
        url
      }
    }
  }
`

const LOGOUT = gql`
  mutation updateUser {
    logout(input: {}) {
      status
    }
  }
`

export function AuthProvider({ children }: { children: ReactNode }) {
  const { data, loading, error } = useQuery(GET_USER)

  const [logOut] = useMutation(LOGOUT, {
    refetchQueries: [{ query: GET_USER }],
  })

  const user = data?.viewer
  const loggedIn = Boolean(user)

  const logout = () => {
    logOut().then(() => (window.location.href = "/"))
  }

  if (loggedIn) {
    WooComState.GetCart()
  }

  const value = {
    loggedIn,
    user,
    loading,
    error,
    logout,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

export default useAuth

import { GlobalState } from "react-gstate"
import CoCartAPI from "@cocart/cocart-rest-api"
import _axios from "axios"
import debounce from "debounce"
import deepEqual from "deep-equal"
import { AuthContext } from "../../hooks/use-auth"

class WoocomStateStore extends GlobalState {
  constructor(props) {
    super(props)
    this.CoCart = null
    this.CartKey = null

    if (typeof window !== "undefined") {
      this.Axios = _axios.create()
      this.Axios.defaults.baseURL = `https://${process.env.GATSBY_WP_URL}/wp-json/`
      this.Axios.defaults.withCredentials = true
      this.Axios.interceptors.request.use(
        async (config) => {
          return config
        },
        (error) => {
          Promise.reject(error)
        }
      )

      // Response interceptor for API calls
      this.Axios.interceptors.response.use(
        (response) => {
          return response
        },
        async (error) => {
          const originalRequest = error.config
          if (error?.response?.status === 403 && !originalRequest._retry) {
            if (error?.response?.data?.data && error?.response?.data?.data[0]) {
              if (error?.response?.data?.data[0].code === "cocart_must_authenticate_user") {
                window.localStorage.removeItem("_WCCSK")
                this.CartKey = null
              }
              return Promise.reject(error)
            } else {
              return Promise.reject(error)
            }
          }
          return Promise.reject(error)
        }
      )
    }
  }

  /*
   *
   * Private Members
   *
   *  */

  #isLoggedIn = () => {
    return !!AuthContext._currentValue.loggedIn
  }

  #isLoading = () => {
    return !!AuthContext._currentValue.loading
  }

  CreateSession = () => {
    if (typeof window !== "undefined") {
      this.CoCart = new CoCartAPI({
        url: `https://${process.env.GATSBY_WP_URL}/`,
      })
      this.CoCart.axiosConfig.withCredentials = true
      this.GetCart()
      this.Axios.get(`woocountrylist/v1/countries`)
        .then((response) => {
          if (response.status === 200) {
            WooComState.setState({ locales: JSON.parse(response.data) })
          }
        })
        .catch((err) => {
          console.error(err)
        })
      //this.Login()
      this.#ReconcileLocalLogin()
    }
  }

  #GetCartKey = () => {
    if (typeof window !== "undefined") {
      if (this.CartKey) {
        return this.CartKey
      }
      let wooCommerceCartStateKey = window.localStorage.getItem("_WCCSK")
      if (wooCommerceCartStateKey) {
        if (isNaN(wooCommerceCartStateKey)) {
          this.CartKey = wooCommerceCartStateKey
          return wooCommerceCartStateKey
        }
      }
    }
  }

  #SetCartKey = (response) => {
    if (typeof window !== "undefined") {
      let cartKey = response.headers["x-cocart-api"]
      if (isNaN(cartKey)) {
        this.CartKey = cartKey
        window.localStorage.setItem("_WCCSK", this.CartKey)
      }
    }
  }

  #CreateParamsWithCartKeyParam = (urlString) => {
    let key = this.#GetCartKey()
    if (key) {
      urlString = `${urlString}/?cart_key=${key}`
    }
    return urlString
  }

  #SetPreviousCartResponse = (response) => {
    if (response.headers) {
      this.#SetCartKey(response)
    }
    if (response.data) {
      WooComState.setState({ previousCartResponse: response.data })
      WooComState.setState({ cartItemCount: response.data.item_count })
      if (response.data.notices) {
        Object.entries(response.data.notices).forEach(([key, value]) => {
          WooComState.setState({ snackbar: value[0] })
        })
      }
      if (response.data.items) {
        let arrayOfProductPromises = []

        response.data.items.forEach((item) => {
          let promise = new Promise((resolve, reject) => {
            this.Axios.get(`/cocart/v1/products/${item.id}`).then((response) => {
              resolve(response)
            })
          })
          arrayOfProductPromises.push(promise)
        })

        Promise.all(arrayOfProductPromises).then((productInfoArray) => {
          response.data.items = response.data.items.map((cartItem) => {
            let foundInProductInfoArray = productInfoArray.find((x) => x.id === cartItem.id)
            return {
              ...cartItem,
              ...foundInProductInfoArray,
            }
          })
          WooComState.setState({ previousCartResponse: response.data })
        })
      }
    }
    WooComState.setState({ hasLoaded: true })
  }

  #SetUser = (response) => {
    let userDisplayName = response?.data?.user_display_name
    let userEmail = response?.data?.user_email
    let userNiceName = response?.data?.user_nicename

    if (userDisplayName || userNiceName || userEmail) {
      WooComState.setState({
        userDisplayName,
        userEmail,
        userNiceName,
      })
    }
  }

  #ReconcileLocalLogin = async () => {
    return new Promise((resolve, reject) => {
      this.Axios.get(`hypematch/v1/user`)
        .then((nextResponse) => {
          if (nextResponse.data) {
            nextResponse.data = JSON.parse(nextResponse.data)
            this.#SetUser(nextResponse)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  #GetToken = () => {
    if (WooComState?.state?.token) {
      return WooComState.state.token
    } else {
      return null
    }
  }

  /*
   *
   * Public Members
   *
   *  */

  GetCart = () => {
    return new Promise((resolve, reject) => {
      this.Axios.get(`cocart/v2/${this.#CreateParamsWithCartKeyParam(`cart`)}`).then((response) => {
        if (response.data) {
          try {
            //response.data = JSON.parse(response.data.replace(/<\/?[^>]+>/gi, ""));
            this.#SetPreviousCartResponse(response)
            if (response.data.customer) {
              if (
                WooComState.state.customerShippingAddress === null ||
                (WooComState.state.customerShippingAddress &&
                  !deepEqual(
                    response.data.customer.customer_shipping_address,
                    WooComState.state.customerShippingAddress
                  ))
              ) {
                if (response.data.customer.shipping_address) {
                  WooComState.setState({
                    customerShippingAddress: response.data.customer.shipping_address,
                  })
                  if (!WooComState.state.hasCalculatedShipping) {
                    this.CalculateShipping()
                  }
                }
                if (response.data.customer.billing_address) {
                  WooComState.setState({
                    customerBillingAddress: response.data.customer.billing_address,
                  })
                }
              }
            }
            if (response.data.currency) {
              WooComState.setState({
                currencySymbol: response.data.currency_symbol,
              })
              WooComState.setState({
                currencyCode: response.data.currency_code,
              })
            }
            resolve()
          } catch (e) {
            console.error(e)
            reject()
          }
        }
      })
    })
  }

  ClearCart = () => {
    return new Promise((resolve, reject) => {
      this.CoCart.post(this.#CreateParamsWithCartKeyParam(`cart/clear`), {})
        .then((response) => {
          window.localStorage.removeItem("_WCCSK")
          this.GetCart()
          resolve()
          this.#SetPreviousCartResponse(response)
        })
        .catch((error) => {
          reject()
          this.ReconstructCart()
          // Invalid request, for 4xx and 5xx statuses
          //console.log("Response Headers:", error.response.headers);
          //console.log("Response Data:", error.response.data);
        })
        .finally(() => {
          // Always executed.
        })
    })
  }

  AddSimpleProductToCart = async (id, quantity = 1) => {
    return new Promise((resolve, reject) => {
      if (this.state?.previousCartResponse?.items) {
        let itemQuestion = this.state.previousCartResponse.items.find((x) => x.id === id)

        if (itemQuestion) {
          this.CoCart.post(this.#CreateParamsWithCartKeyParam(`cart/item/${itemQuestion.item_key}`), {
            quantity: itemQuestion.quantity.value + quantity,
            return_cart: true,
          })
            .then((response) => {
              resolve()
              this.#SetPreviousCartResponse(response)
            })
            .catch((error) => {
              this.ReconstructCart()
              reject()
              // Invalid request, for 4xx and 5xx statuses
              //console.log("Response Headers:", error.response.headers);
              //console.log("Response Data:", error.response.data);
            })
            .finally(() => {
              // Always executed.
            })

          return
        }
      }

      this.CoCart.post(this.#CreateParamsWithCartKeyParam("cart/add-item"), {
        id: id.toString(),
        quantity: quantity.toString(),
      })
        .then((response) => {
          this.#SetPreviousCartResponse(response)
          resolve()
        })
        .catch((error) => {
          reject()
          // Invalid request, for 4xx and 5xx statuses
          console.log("Response Status:", error)
          //console.log("Response Headers:", error.response.headers);
          //console.log("Response Data:", error.response.data);
        })
        .finally(() => {
          // Always executed.
        })
    })
  }

  AddMatchedProductToCart = async ({ variation, quantity = 1 }) => {
    let parsedVariation = JSON.parse(variation)

    let data = {
      id: parsedVariation.id,
      quantity: quantity.toString(),
      variation: parsedVariation.variation,
    }

    return new Promise((resolve, reject) => {
      this.CoCart.post(this.#CreateParamsWithCartKeyParam("cart/add-item"), data)
        .then((response) => {
          this.#SetPreviousCartResponse(response)
          resolve()
        })
        .catch((error) => {
          // Invalid request, for 4xx and 5xx statuses
          console.log("Response Status:", error)
          //console.log("Response Headers:", error.response.headers);
          //console.log("Response Data:", error.response.data);
          reject(error)
        })
        .finally(() => {
          // Always executed.
        })
    })
  }

  ApplyCoupon = async (couponCode) => {
    let data = {
      coupon: couponCode,
    }

    return new Promise((resolve, reject) => {
      this.Axios.post(`cocart/v1/${this.#CreateParamsWithCartKeyParam(`coupon`)}`, data)
        .then((response) => {
          if (response.data) {
            this.GetCart()
            resolve()
          }
        })
        .catch((error) => {
          reject(error.response.data)
          this.ReconstructCart()
          // Invalid request, for 4xx and 5xx statuses
          console.log("Response Status:", error.response.data)
          //console.log("Response Headers:", error.response.headers);
          //console.log("Response Data:", error.response.data);
        })
        .finally(() => {
          // Always executed.
        })
    })
  }

  RemoveCoupon = (couponCode) => {
    let data = {
      coupon: couponCode,
    }

    this.Axios.delete(`cocart/v1/${this.#CreateParamsWithCartKeyParam(`coupon`)}`, { data })
      .then((response) => {
        if (response.data) {
          // this.#SetPreviousCartResponse(response.data);
          this.GetCart()
        }
      })
      .catch((error) => {
        this.ReconstructCart()
        // Invalid request, for 4xx and 5xx statuses
        console.log("Response Status:", error)
        //console.log("Response Headers:", error.response.headers);
        //console.log("Response Data:", error.response.data);
      })
      .finally(() => {
        // Always executed.
      })
  }

  ReconstructCart = () => {
    const currentCart = { ...this.state.previousCartResponse }
    window.localStorage.removeItem("_WCCSK")
    this.CartKey = null

    this.GetCart().then(() => {
      if (currentCart?.items) {
        currentCart.items.forEach((item) => {
          this.AddSimpleProductToCart(item.id, item.quantity.value)
        })
      }

      if (currentCart?.coupons) {
        currentCart.coupons.forEach((coupon) => {
          this.ApplyCoupon(coupon.coupon)
        })
      }
    })
  }

  RemoveProductFromCart = (itemKey) => {
    return new Promise((resolve, reject) => {
      this.CoCart.delete(this.#CreateParamsWithCartKeyParam(`cart/item/${itemKey}`))
        .then((response) => {
          this.#SetPreviousCartResponse(response)
          resolve()
        })
        .catch((error) => {
          this.ReconstructCart()
          // Invalid request, for 4xx and 5xx statuses
          console.log("Response Status:", error)
          //console.log("Response Headers:", error.response.headers);
          //console.log("Response Data:", error.response.data);
          reject()
        })
        .finally(() => {
          // Always executed.
        })
    })
  }

  ChangeItemQuantity = (itemKey, quantity) => {
    return new Promise((resolve, reject) => {
      this.CoCart.post(this.#CreateParamsWithCartKeyParam(`cart/item/${itemKey}`), {
        quantity: quantity,
        return_cart: true,
      })
        .then((response) => {
          this.#SetPreviousCartResponse(response)
          resolve()
        })
        .catch((error) => {
          this.ReconstructCart()
          // Invalid request, for 4xx and 5xx statuses
          console.log("Response Status:", error)
          //console.log("Response Headers:", error.response.headers);
          //console.log("Response Data:", error.response.data);
          reject(error)
        })
        .finally(() => {
          // Always executed.
        })
    })
  }

  CalculateShipping = () => {
    if (WooComState.state.customerShippingAddress?.shipping_country) {
      this.Axios.post(`cocart/v1/${this.#CreateParamsWithCartKeyParam(`calculate/shipping`)}`, {
        country: WooComState.state.customerShippingAddress?.shipping_country,
      })
        .then((response) => {
          if (response.data) {
            WooComState.setState({ hasCalculatedShipping: true })
            this.GetCart()
          }
        })
        .catch(() => {
          this.ReconstructCart()
        })
    }
  }

  SetShippingAddress = (country, state, city, postcode) => {
    WooComState.setState({ hasCalculatedShipping: false })
    this.Axios.post(`cocart/v1/${this.#CreateParamsWithCartKeyParam(`calculate/shipping`)}`, {
      country,
      state,
      city,
      postcode,
    })
      .then((response) => {
        if (response.data) {
          console.log(response.data)
          WooComState.setState({ hasCalculatedShipping: true })
          this.GetCart()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  SetShippingMethod = debounce((method) => {
    console.log("Bewertung wird übertragen...")
    this.Axios.post(`cocart/v1/${this.#CreateParamsWithCartKeyParam(`shipping-methods`)}`, { key: method }).then(
      (response) => {
        if (response.data) {
          try {
            response.data = JSON.parse(response.data)
            if (response.data) {
              WooComState.setState({ currentShippingMethod: method })
            }
          } catch (e) {
            console.error(e)
          }
        }
      }
    )
  }, 700)

  PostReview = (reviewContent, productId, rating, displayName, email) => {
    return new Promise((resolve, reject) => {
      this.Axios.post(`cocart/v1/products/reviews`, {
        product_id: productId,
        review: reviewContent,
        reviewer: WooComState.state.userDisplayName || displayName,
        reviewer_email: WooComState.state.userEmail || email,
        rating: rating,
      })
        .then((response) => {
          if (response.data) {
            resolve()
          }
        })
        .catch((err) => {
          console.log(err?.response)
          WooComState.setState({ snackbar: err?.response?.data?.message })
          reject(err)
        })
    })
  }
}

const WooComState = new WoocomStateStore({
  cartItemCount: 0,
  previousCartResponse: {},
  snackbar: null,
  hasLoaded: false,
  token: null,
  userDisplayName: null,
  userEmail: null,
  userNiceName: null,
  currencySymbol: "€",
  currencyCode: "EUR",
  customerBillingAddress: null,
  customerShippingAddress: null,
  hasCalculatedShipping: false,
  currentShippingMethod: null,
  locales: null,
})

WooComState.CreateSession()

export default WooComState
